import { Avatar, Button } from "@mui/material";
import { getDatabase, onValue, orderByChild, query, ref, remove, update } from "firebase/database";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import User from "../../models/User";
import { parseUser } from "../../utils";

function AddFriends() {
  const navigate = useNavigate();
  const auth = useAuth();
  const db = getDatabase();
  const [currentUser, setCurrentUser] = useState<User>();
  const [users, setUsers] = useState<User[]>([]);
  const [initiallyFollowing, setInitiallyFollowing] = useState<string[]>([]);

  useEffect(() => {
    const uid = auth.currentUser?.uid;
    if (uid) {
      const currentUserRef = ref(db, 'users/' + uid);
      const unsubscribe = onValue(currentUserRef, (snapshot) => {
        const user = parseUser(uid, snapshot.val());
        setCurrentUser(user);
        if (initiallyFollowing.length === 0) {
          setInitiallyFollowing(user.following);
        }
      })
      return () => {
        unsubscribe();
      }
    }
  }, [db, auth.currentUser?.uid, initiallyFollowing]);

  useEffect(() => {
    if (currentUser) {
      const usersRef = query(ref(db, 'users'), orderByChild('username'));
      const unsubscribe = onValue(usersRef, (snapshot) => {
        const tempUsers: User[] = [];
        snapshot.forEach((childSnapshot) => {
          const childKey = childSnapshot.key!;
          const childData = childSnapshot.val();
          const user = parseUser(childKey, childData);
          if (currentUser.followers.includes(user.uid) && !initiallyFollowing.includes(user.uid)) {
            tempUsers.push(user);
          }
        })
        setUsers(tempUsers);
      });
      return () => {
        unsubscribe();
      }
    }
  }, [db, currentUser, initiallyFollowing]);

  const isFollowing = (user: User) => {
    return currentUser && currentUser.following.includes(user.uid);
  }

  const follow = (user: User) => {
    if (currentUser) {
      // add following to the current user
      const currentUserRef = ref(db, 'users/' + currentUser.uid);
      const currentUserUpdates: any = {};
      currentUserUpdates['/following/' + user.uid] = true;
      update(currentUserRef, currentUserUpdates);

      // add the follower to the user
      const userRef = ref(db, 'users/' + user.uid);
      const userUpdates: any = {};
      userUpdates['/followers/' + currentUser.uid] = true;
      update(userRef, userUpdates);
    }
  }

  const unfollow = (user: User) => {
    if (currentUser) {
      // remove the following reference on the current user
      const followingRef = ref(db, 'users/' + currentUser.uid + '/following/' + user.uid);
      remove(followingRef);

      // remove the follower reference on the user
      const followerRef = ref(db, 'users/' + user.uid + '/followers/' + currentUser.uid);
      remove(followerRef);
    }
  }

  return (
    <>
      <Button onClick={() => navigate(-1)}>Back</Button>
      <div>
        {users.map((user) => 
          <div key={user.uid}>
            <Avatar alt={user.displayName} src={user.photoURL} />
            <div>{user.displayName}</div>
            <div>{user.username}</div>
            {isFollowing(user) ? (
              <Button onClick={() => unfollow(user)}>Added</Button>
            ) : (
              <Button onClick={() => follow(user)}>Add</Button>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default AddFriends;