import Identity from "./models/Identity";
import User from "./models/User";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PinterestIcon from '@mui/icons-material/Pinterest';
import RedditIcon from '@mui/icons-material/Reddit';
import ChatIcon from '@mui/icons-material/Chat';
import TwitterIcon from '@mui/icons-material/Twitter';
import LanguageIcon from '@mui/icons-material/Language';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkIcon from '@mui/icons-material/Link';

const providers = [
  'facebook',
  'instagram',
  'twitter', 
  'youtube', 
  'snapchat', 
  'linkedin', 
  'linkedinBusiness', 
  'whatsapp', 
  'reddit', 
  'soundcloud', 
  'twitch', 
  'tiktok', 
  'spotify', 
  'spotifyArtist', 
  'wechat', 
  'pinterest', 
  'website', 
  'reviews',
]

function parseUser(uid: string, data: any): User {

  const identities = data.identities ? Object.entries(data.identities).map(([provider, username]) => ({ provider, username: username as string })) : [];
  const followers = data.followers ? Object.entries(data.followers).map(([uid]) => uid) : [];
  const following = data.following ? Object.entries(data.following).map(([uid]) => uid) : [];
  const activities = data.activities ? Object.entries(data.activities).map(([uid]) => uid) : [];

  return {
    uid,
    username: data.username,
    displayName: data.displayName,
    photoURL: data.photoURL,
    email: data.email,
    phoneNumber: data.phoneNumber,
    bio: data.bio,
    identities,
    karma: data.karma || 0,
    followers,
    following,
    activities,
  };
}

function providerTitle(provider: string): string {
  switch (provider) {
    case 'facebook':
      return "Facebook"
    case 'instagram':
      return "Instagram"
    case 'twitter':
      return "Twitter"
    case 'youtube':
      return "YouTube"
    case 'snapchat':
      return "Snapchat"
    case 'linkedin':
      return "LinkedIn"
    case 'whatsapp':
      return "WhatsApp"
    case 'reddit':
      return "Reddit"
    case 'soundcloud':
      return "SoundCloud"
    case 'twitch':
      return "Twitch"
    case 'tiktok':
      return "TikTok"
    case 'spotify':
      return "Spotify"
    case 'wechat':
      return "WeChat"
    case 'pinterest':
      return "Pinterest"
    case 'linkedinBusiness':
      return "LinkedIn Business"
    case 'spotifyArtist':
      return "Spotify Artist"
    case 'website':
      return "Website"
    case 'reviews':
      return "Reviews"
    default:
      return '';
  }
}

function providerLink(identity: Identity): string {
  switch (identity.provider) {
    case 'facebook':
      return `https://facebook.com/${identity.username}`
    case 'instagram':
      return `https://instagram.com/${identity.username}`
    case 'twitter':
      return `https://twitter.com/${identity.username}`
    case 'youtube':
      return `${identity.username}`
    case 'snapchat':
      return `https://snapchat.com/add/${identity.username}`
    case 'linkedin':
      return `https://linkedin.com/in/${identity.username}`
    case 'whatsapp':
      return `https://wa.me/${identity.username}`
    case 'reddit':
      return `https://reddit.com/u/${identity.username}`
    case 'soundcloud':
      return `https://soundcloud.com/${identity.username}`
    case 'twitch':
      return `https://twitch.com/${identity.username}`
    case 'tiktok':
      return `${identity.username}`
    case 'spotify':
      return `https://open.spotify.com/user/${identity.username}`
    case 'wechat':
      return `weixin://dl/chat?${identity.username}`
    case 'pinterest':
      return `https://pinterest.com/${identity.username}`
    case 'linkedinBusiness':
      return `https://linkedin.com/company/${identity.username}`
    case 'spotifyArtist':
      return `https://open.spotify.com/artist/${identity.username}`
    case 'website':
      return `${identity.username}`
    case 'reviews':
      return `${identity.username}`
    default:
      return `${identity.username}`;
  }
}

function providerColor(provider: string): string {
  switch (provider) {
    case 'facebook':
      return "#3B5998"
    case 'instagram':
      return "#E1306C"
    case 'twitter':
      return "#1DA1F2"
    case 'youtube':
      return "#FF0000"
    case 'snapchat':
      return "#FFFC00"
    case 'linkedin':
      return "#0077B5"
    case 'whatsapp':
      return "#25D366"
    case 'reddit':
      return "#FF4500"
    case 'soundcloud':
      return "#FF7700"
    case 'twitch':
      return "#6441A5"
    case 'tiktok':
      return "#212121"
    case 'spotify':
      return "#1DB954"
    case 'wechat':
      return "#1DB954"
    case 'pinterest':
      return "#C8232C"
    case 'linkedinBusiness':
      return "#0077B5"
    case 'spotifyArtist':
      return "#1DB954"
    case 'website':
      return "#005A9C"
    case 'reviews':
      return "#ff0000"
    default:
      return '#000000'
  }
}

function providerIcon(provider: string): React.ReactElement {
  switch (provider) {
    case 'facebook':
      return <FacebookIcon />
    case 'instagram':
      return <InstagramIcon />
    case 'twitter':
      return <TwitterIcon />
    case 'youtube':
      return <YouTubeIcon />
    // case 'snapchat':
    //   return <div/>
    case 'linkedin':
      return <LinkedInIcon />
    case 'whatsapp':
      return <WhatsAppIcon />
    case 'reddit':
      return <RedditIcon />
    // case 'soundcloud':
    //   return <div />
    // case 'twitch':
    //   return <div />
    // case 'tiktok':
    //   return <div />
    // case 'spotify':
    //   return <div />
    // case 'wechat':
    //   return <div />
    case 'pinterest':
      return <PinterestIcon />
    case 'linkedinBusiness':
      return <LinkedInIcon />
    // case 'spotifyArtist':
    //   return <div />
    case 'website':
      return <LanguageIcon />
    case 'reviews':
      return <ChatIcon />
    default:
      return <LinkIcon/>
  }
}

function providerTextColor(provider: string): string {
  switch (provider) {
    case 'snapchat':
      return "#000000"
    default:
      return '#ffffff'
  }
}

function providerUsername(provider: string): string {
  switch (provider) {
    case 'facebook':
      return "Username"
    case 'instagram':
      return "Username"
    case 'twitter':
      return "Username"
    case 'youtube':
      return "Channel Link"
    case 'snapchat':
      return "Username"
    case 'linkedin':
      return "Username"
    case 'whatsapp':
      return "Username"
    case 'reddit':
      return "Username"
    case 'soundcloud':
      return "Username"
    case 'twitch':
      return "Username"
    case 'tiktok':
      return "Link"
    case 'spotify':
      return "Profile Code"
    case 'wechat':
      return "ID"
    case 'pinterest':
      return "Username"
    case 'linkedinBusiness':
      return "Username"
    case 'spotifyArtist':
      return "Profile Code"
    case 'website':
      return "URL"
    case 'reviews':
      return "URL"
    default:
      return 'Username';
  }
}

function providerInstructions(provider: string) {
  switch (provider) {
    case 'facebook':
      return "Tap your profile tab\n\nTap the gray menu button to the right of the Add Story button\n\nFind your username under Your Profile Link"
    case 'instagram':
      return "Tap your profile tab\n\nTap the Edit Profile button\n\nFind your username in the Username field"
    case 'twitter':
      return "Tap your profile picture button\n\nFind your username under your display name"
    case 'youtube':
      return "Tap your profile picture button\n\nTap Your Channel\n\nTap the 3 dots in the top right\n\nTap Copy Link \n\nPaste only username \n\n\nIf you have < 100+ subscribers you will have a different process: \n\nCopy username after /channel/ \n\nex: youtube.com/channel/UCP3TA9MDRjocg9bjiFdsZpA"
    case 'snapchat':
      return "Tap your profile picture button\n\nFind your username under your display name"
    case 'linkedin':
      return "Tap your profile picture\n\nScroll down to find your username under Contact - Your Profile"
    case 'whatsapp':
      return "Tap the settings tab\n\nTap to edit your profile\n\nYour username is your phone number without any zeroes, brackets, or dashes with country code"
    case 'reddit':
      return "Tap your profile button\n\nFind your username under your avatar"
    case 'soundcloud':
      return "Tap the Library tab\n\nTap the profile button\n\nFind your username in the Your profile field"
    case 'twitch':
      return "Tap the profile button\n\nFind your username under your avatar"
    case 'tiktok':
      return "Tap the Me tab\n\nTap the Privacy and settings button at the top right\n\nTap Share profile\n\nSelect Copy Link\n\nPaste link above"
    case 'spotify':
      return "Tap the Settings button\n\nTap View Profile\n\nTap the menu button at the top right\n\nTap Share\n\nSelect Copy Link\n\nFind your username after user/ in the link you copied"
    case 'wechat':
      return "Tap the your Profile and copy your ID if you have set one. If you have not set one, you can set one at this time. Note: Your ID can only be set once."
    case 'pinterest':
      return "Tap your profile picture \n\nFind your username under your display name"
    case 'linkedinBusiness':
      return "Tap your company profile\n\nTap the 3 dots in the top right.\n\nTap Copy Link.\n\nPaste only company username\n\n\nCopy company username after /company/ \n\nex: linkedin.com/company/addupapp"
    case 'spotifyArtist':
      return "Tap the Settings button\n\nTap View Profile\n\nTap the menu button at the top right\n\nTap Share\n\nSelect Copy Link\n\nFind your username after user/ in the link you copied"
    case 'website':
      return "Tap line above to confirm your website."
    case 'reviews':
      return "Tap line above to confirm your profile"
    default:
      return '';
  }
}

export { providers, parseUser, providerTitle, providerLink, providerColor, providerIcon, providerTextColor, providerUsername, providerInstructions };