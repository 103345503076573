import { Route, Routes } from "react-router-dom";
import LeaderboardLayout from "../LeaderboardLayout/LeaderboardLayout";
import LeaderboardList from "../LeaderboardList/LeaderboardList";

function Leaderboard() {

  return (
    <Routes>
      <Route path="/" element={<LeaderboardLayout />}>
        <Route index element={<LeaderboardList isFollowing={false} />} />
        <Route path="everyone" element={<LeaderboardList isFollowing={false} />} />
        <Route path="following" element={<LeaderboardList isFollowing={true} />} />
      </Route>
    </Routes>
  );
}

export default Leaderboard;