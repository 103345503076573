import useAuth from "../../hooks/useAuth";
import { QRCodeSVG } from 'qrcode.react';
import { Avatar, Dialog, DialogContent, ListItemText, Stack } from "@mui/material";
import { getDatabase, onValue, ref } from "firebase/database";
import { useEffect, useState } from "react";
import User from "../../models/User";
import { parseUser } from "../../utils";

function QrCodeDialog({ open, onClose }: { open: boolean, onClose: () => void }) {
  const auth = useAuth();
  const db = getDatabase();
  const [user, setUser] = useState<User>();

  useEffect(() => {
    const uid = auth.currentUser?.uid;
    if (uid) {
      const currentUserRef = ref(db, 'users/' + uid);
      const unsubscribe = onValue(currentUserRef, (snapshot) => {
        const user = parseUser(uid, snapshot.val());
        setUser(user);
      })
      return () => {
        unsubscribe();
      }
    }
  }, [db, auth.currentUser?.uid]);

  const protocol = window.location.protocol;
  const domain = window.location.host;
  const url = protocol + '//' + domain + '/add/' + user?.uid;
  console.log('url', url)

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        {user &&
          <Stack alignItems="center" rowGap={2} minWidth={200}>
            <QRCodeSVG value={url} style={{margin: 'auto'}} />
            <Avatar alt={user.displayName} src={user.photoURL} sx={{ width: 64, height: 64 }} />
            <ListItemText
              sx={{ textAlign: 'center' }}
              primary={user.displayName}
              secondary={`${user.username} • ${user.karma}`}
            />
          </Stack>
        }
      </DialogContent>
    </Dialog>
  )
}

export default QrCodeDialog;