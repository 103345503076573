import { AppBar, Avatar, Box, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Toolbar } from "@mui/material";
import React, { ReactNode, useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import useAuth from "../../hooks/useAuth";
import logo from '../App/logo.svg';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import PeopleIcon from '@mui/icons-material/People';
import QrCodeIcon from '@mui/icons-material/QrCode';
import QrCodeDialog from "../QrCodeDialog/QrCodeDialog";

function HomeLayout() {
  const auth = getAuth();
  const navigate = useNavigate();
  const [isSignedIn, setIsSignedIn] = useState(true);
  const addupAuth = useAuth();
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const location = useLocation();
  const [isQrOpen, setQrOpen] = useState(false);

  const onClick = () => {
    signOut(auth).then(() => {
      // Sign-out successful.
      setIsSignedIn(false);
    }).catch((error) => {
      // An error happened.
      console.error(error);
    });
  }

  useEffect(() => {
    if (!isSignedIn) {
      navigate('/');
    }
  }, [isSignedIn, navigate]);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const displayName = addupAuth.currentUser?.displayName || '';
  const photoURL = addupAuth.currentUser?.photoURL || '';
  const drawerWidth = 240;

  const CustomListItem = ({ to, primary, icon, isDefault }: { to: string, primary: string, icon: ReactNode, isDefault: boolean }) => {
    const isSelected = location.pathname.includes(to) || (isDefault && location.pathname === '/');
    return (
      <ListItemButton 
        selected={isSelected} 
        component={Link} 
        to={to}
        sx={{
          '&.Mui-selected': {
            backgroundColor: 'rgba(0, 0, 0, 0.25)',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.25)',
            }
          }
        }}
      >
        <ListItemIcon sx={{ color: 'white', opacity: isSelected ? 1 : 0.5 }}>{icon}</ListItemIcon>
        <ListItemText primary={primary} sx={{ color: 'white' }} />
      </ListItemButton>
   )
  }

  const showQrCode = () => {
    setQrOpen(true);
  }

  
  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar 
        position="fixed"
        sx={{ 
          width: `calc(100% - ${drawerWidth}px)`, 
          ml: `${drawerWidth}px`,
          bgcolor: 'white',
          borderBottom: 1, 
          borderColor: 'divider'
        }}
        elevation={0}
      >
        <Toolbar>
          <Box sx={{ flexGrow: 1 }}></Box>
          <Box sx={{ flexGrow: 0 }}>
            <IconButton onClick={showQrCode}>
              <QrCodeIcon />
            </IconButton>
            <IconButton onClick={handleOpenUserMenu}>
              <Avatar alt={displayName} src={photoURL} />
            </IconButton>
            <Menu 
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)} 
              onClose={handleCloseUserMenu}
            >
              <MenuItem component={Link} to="/settings/accounts" onClick={handleCloseUserMenu}>Add Your Accounts</MenuItem>
              <MenuItem component={Link} to="/settings/profile" onClick={handleCloseUserMenu}>Edit Profile</MenuItem>
              
              {auth.currentUser?.phoneNumber &&
                <MenuItem component={Link} to="/settings/phone" onClick={handleCloseUserMenu}>Change Your Phone Number</MenuItem>
              }
              {auth.currentUser?.email &&
                <MenuItem component={Link} to="/settings/email"onClick={handleCloseUserMenu}>Change Your Email</MenuItem>
              }
              
              <Divider />
              <MenuItem onClick={handleCloseUserMenu}>Privacy Policy</MenuItem>
              <MenuItem onClick={handleCloseUserMenu}>Terms of Use</MenuItem>
              <Divider />
              <MenuItem onClick={onClick}>Sign Out</MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer 
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            bgcolor: 'primary.main'
          },          
        }}
        variant="permanent"
      >

        <Box sx={{p: 2, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <img src={logo} style={{width: 48, display: 'block'}} alt="AddUp" />
        </Box>

        <List>
          <ListItem disablePadding>
            <CustomListItem isDefault={true} to="leaderboard" primary="Leaderboard" icon={<EmojiEventsIcon/>} />
          </ListItem>
          <ListItem disablePadding>
            <CustomListItem isDefault={false} to="friends" primary="Friends" icon={<PeopleIcon/>} />
          </ListItem>
        </List>
      </Drawer>
      <Box 
        component="main"
        sx={{ 
          flexGrow: 1, 
          // p: 3 
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>

      <QrCodeDialog open={isQrOpen} onClose={() => setQrOpen(false)} />

    </Box>
  );
}

export default HomeLayout;