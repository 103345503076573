import { Avatar, Box, Button, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { getDatabase, onValue, ref, update } from "firebase/database";
import { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import User from "../../models/User";
import { providers, parseUser, providerColor, providerIcon, providerTitle } from "../../utils";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Identity from "../../models/Identity";
import ProviderDialog from "../ProviderDialog/ProviderDialog";

function MyProfile() {
  const db = getDatabase();
  const auth = useAuth();
  const [user, setUser] = useState<User>();
  const [selectedIdentity, setSelectedIdentity] = useState<Identity>();

  useEffect(() => {
    const uid = auth.currentUser?.uid;
    if (uid) {
      const currentUserRef = ref(db, 'users/' + uid);
      const unsubscribe = onValue(currentUserRef, (snapshot) => {
        const user = parseUser(uid, snapshot.val());
        setUser(user);
      })
      return () => {
        unsubscribe();
      }
    }
  }, [db, auth.currentUser?.uid]);

  const getIdentity = (provider: string) => {
    if (user) {
      return user.identities
        .filter((identity) => identity.provider === provider)?.[0];
    }
  }

  const handleClose = () => {
    setSelectedIdentity(undefined);
  }

  const handleSave = (identity: Identity) => {
    if (user) {
      const currentUserRef = ref(db, 'users/' + user.uid);
      const currentUserUpdates: any = {};
      currentUserUpdates['/identities/' + identity.provider] = identity.username;
      update(currentUserRef, currentUserUpdates);

    }
    setSelectedIdentity(undefined);
  }
  
  return (
    <Box sx={{p: 2}}>
      {user &&
        <Box sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
          <Avatar alt={user.displayName} src={user.photoURL} sx={{ width: 128, height: 128 }} />
          <Typography variant="h5" align="center">{user.displayName}</Typography>
          <Typography variant="subtitle1">{user.username} • {user.karma}</Typography>
          <Typography variant="body2" align="center" style={{ whiteSpace: 'pre-line' }}>{user.bio}</Typography>
          <List sx={{mt: 2, width: '100%'}}>
            {providers.map((provider) => {
              const identity = getIdentity(provider);
              return (
                <ListItem key={provider} onClick={() => setSelectedIdentity(identity ? identity : {provider, username: ''})}>
                  <ListItemIcon>
                    <Typography sx={{color: providerColor(provider)}}>
                      {providerIcon(provider)}
                    </Typography>
                  </ListItemIcon>
                  <ListItemText primary={providerTitle(provider)} />
                  {identity ? (
                    <>
                      <ListItemText primary={identity.username} sx={{textAlign: 'right'}} />
                      <CheckCircleIcon color="success" sx={{pl: 2}} />
                    </>
                  ) : (
                    <Button variant="outlined">Add Account</Button>
                  )}
                </ListItem>
              )
            })}
          </List>
        </Box>
      }
      {selectedIdentity &&
        <ProviderDialog 
          open={selectedIdentity !== undefined} 
          onClose={handleClose} 
          identity={selectedIdentity} 
          onSave={handleSave}
        />
      }
    </Box>
  )
}

export default MyProfile;