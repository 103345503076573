import { Avatar, Box, Drawer, ListItemText, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import { getDatabase, ref, onValue, query, orderByChild, Unsubscribe } from "firebase/database";
import { useEffect, useRef, useState } from "react";
import { useLocation, Location } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import User from "../../models/User";
import { parseUser } from "../../utils";
import UserDetail from "../UserDetail/UserDetail";

function LeaderboardList({ isFollowing }: { isFollowing: boolean }) {

  const auth = useAuth();
  const db = getDatabase();
  const [users, setUsers] = useState<User[]>([]);
  const [currentUser, setCurrentUser] = useState<User>();
  const [selectedUser, setSelectedUser] = useState<User>();
  const location = useLocation()
  const previous = useRef<Location>();

  useEffect(() => {
    if (previous.current?.pathname !== location.pathname) {
      setUsers([]);
      setSelectedUser(undefined);
    }
    previous.current = location;
  }, [location]);

  useEffect(() => {
    const uid = auth.currentUser?.uid;
    if (isFollowing && uid) {
      const currentUserRef = ref(db, 'users/' + uid);
      const unsubscribe = onValue(currentUserRef, (snapshot) => {
        setCurrentUser(parseUser(uid, snapshot.val()));
      })
      return () => {
        unsubscribe();
      }
    }
  }, [db, isFollowing, auth.currentUser?.uid]);

  useEffect(() => {
    if (!isFollowing) {
      const usersRef = query(ref(db, 'users'), orderByChild('karma'));
      const unsubscribe = onValue(usersRef, (snapshot) => {
        const tempUsers: User[] = [];
        snapshot.forEach((childSnapshot) => {
          const childKey = childSnapshot.key!;
          const childData = childSnapshot.val();
          const user = parseUser(childKey, childData);
          tempUsers.push(user);
        })
        tempUsers.sort((a, b) => b.karma - a.karma);
        setUsers(tempUsers);

      });
      return () => {
        unsubscribe();
      }
    }
  }, [db, isFollowing]);

  useEffect(() => {
    const observers: Unsubscribe[] = [];
    if (currentUser) {
      for (const uid of currentUser.following) {
        const userRef = ref(db, 'users/' + uid);
        const unsubscribe = onValue(userRef, (snapshot) => {
          const user = parseUser(uid, snapshot.val());
          setUsers(u => {
            let hasUser = false;
            for (const existingUser of u) {
              if (existingUser.uid === uid) {
                hasUser = true;
                break;
              }
            }
            if (!hasUser) {
              return [...u, user].sort((a, b) => b.karma - a.karma);
            } else {
              return u.sort((a, b) => b.karma - a.karma);
            }
          });
        });
        observers.push(unsubscribe);
      }
    }
    return () => {
      for (const unsubscribe of observers) {
        unsubscribe();
      }
    }
  }, [db, currentUser]);

  const drawerWidth = 480;

  return (
    <>
      <TableContainer>
        <Table>
          <TableBody>
            {users.map((user, index) =>
              <TableRow key={user.uid} onClick={() => setSelectedUser(user)}>
                <TableCell align="right" sx={{pr: 0}}>{index + 1}</TableCell>
                <TableCell width={40}>
                  <Avatar alt={user.displayName} src={user.photoURL} />
                </TableCell>
                <TableCell sx={{pl: 0}}>
                  <ListItemText
                    primary={user.displayName}
                    secondary={user.username}
                  />
                </TableCell>
                <TableCell align="right">{user.karma}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Drawer sx={{width: drawerWidth}} anchor="right" open={!!selectedUser} onClose={() => setSelectedUser(undefined)}>
        <Box sx={{width: drawerWidth, p: 2}}>
          {selectedUser &&
            <UserDetail uid={selectedUser.uid} />
          }
        </Box>
      </Drawer>
    </>
  );
}

export default LeaderboardList;