import { Navigate, useLocation } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

function RequireAuth({ children }: { children: JSX.Element }) {
    const auth = useAuth();
    const location = useLocation();

    if (!auth.isLoaded) {
        return null;
    }

    if (!auth.currentUser) {
        return <Navigate to="/login" state={{ from: location }} replace />
    }

    return children;
}

export default RequireAuth;