import { User } from "firebase/auth";
import { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import AuthContext from "../../contexts/AuthContext";

function AuthProvider({ children }: { children: React.ReactNode }) {
    const auth = getAuth();
    const [currentUser, setCurrentUser] = useState<User | null>(auth.currentUser);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            setCurrentUser(user);
            setIsLoaded(true);
        });
    }, [auth]);

    const value = { currentUser, isLoaded };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthProvider;