import { Box, Button, Tab, Tabs } from "@mui/material";
import { Link, Outlet, useLocation } from "react-router-dom";

function LeaderboardLayout() {
  const allTabs = ['/leaderboard/everyone', '/leaderboard/following'];
  // const params = useParams();
  const location = useLocation();
  let pathname = location.pathname;
  if (pathname.split('/').length < 3) {
    pathname = '/leaderboard/everyone';
  }

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={pathname || '/leaderboard/everyone'}>
          <Tab label="Everyone" value="/leaderboard/everyone" component={Link} to={allTabs[0]} />
          <Tab label="Following" value="/leaderboard/following" component={Link} to={allTabs[1]} />
        </Tabs>
      </Box>
      <Box sx={{p: 2}}>
        <Button variant="contained" sx={{ mb: 2 }}component={Link} to="/add">Add Friends</Button>
        <Outlet />
      </Box>
    </>
  );
}

export default LeaderboardLayout;