import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link, TextField } from "@mui/material";
import { useState } from "react";
import Identity from "../../models/Identity";
import { providerColor, providerIcon, providerInstructions, providerLink, providerTitle, providerUsername } from "../../utils";

function ProviderDialog({ open, onClose, identity, onSave }: { open: boolean, onClose: () => void, identity: Identity, onSave: (identity: Identity) => void }) {
  const [username, setUsername] = useState(identity.username);

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Add Account</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{color: providerColor(identity.provider)}}>
          {providerIcon(identity.provider)}
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="username"
          label={`Your ${providerTitle(identity.provider)} ${providerUsername(identity.provider)}`}
          type="text"
          fullWidth
          variant="standard"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        {username &&
          <>
            <DialogContentText variant="body2">
              <Link href={providerLink({provider: identity.provider, username})} target="_blank" rel="noreferrer">{providerLink({provider: identity.provider, username})}</Link>
            </DialogContentText>  
            <DialogContentText variant="body2">
              Tap the link above to confirm your profile
            </DialogContentText>  
          </> 
        }
        <DialogContentText variant="h6">
          {`How to find your ${providerUsername(identity.provider)}:`}
        </DialogContentText>
        <DialogContentText variant="body2" sx={{mt: 2, whiteSpace: 'pre-line'}}>
          {providerInstructions(identity.provider)}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={() => onSave({ provider: identity.provider, username })}>Save</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ProviderDialog;