import { Avatar, Box, Button, Drawer, ListItemText, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import { getDatabase, onValue, ref, Unsubscribe } from "firebase/database";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import User from "../../models/User";
import { parseUser } from "../../utils";
import UserDetail from "../UserDetail/UserDetail";

function Friends() {
  const auth = useAuth();
  const db = getDatabase();
  const [currentUser, setCurrentUser] = useState<User>();
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User>();

  useEffect(() => {
    const uid = auth.currentUser?.uid;
    if (uid) {
      const currentUserRef = ref(db, 'users/' + uid);
      const unsubscribe = onValue(currentUserRef, (snapshot) => {
        const user = parseUser(uid, snapshot.val());
        setCurrentUser(user);
      })
      return () => {
        unsubscribe();
      }
    }
  }, [db, auth.currentUser?.uid]);

  useEffect(() => {
    if (currentUser) {
      const observers: Unsubscribe[] = [];
      for (const uid of currentUser.following) {
        const userRef = ref(db, 'users/' + uid);
        const unsubscribe = onValue(userRef, (snapshot) => {
          const user = parseUser(uid, snapshot.val());
          setUsers(u => {
            let hasUser = false;
            for (const existingUser of u) {
              if (existingUser.uid === uid) {
                hasUser = true;
                break;
              }
            }
            if (!hasUser) {
              return [...u, user].sort((a, b) => b.karma - a.karma);
            } else {
              return u.sort((a, b) => b.karma - a.karma);
            }
          });
        });
        observers.push(unsubscribe);
      }
    }
  }, [db, currentUser]);

  const drawerWidth = 480;

  // return (
  //   <Stack direction="row">
  //     <div>
  //       {users.map(user => 
  //         <div key={user.uid} onClick={() => setSelectedUser(user)}>
  //           <Avatar alt={user.displayName} src={user.photoURL} />
  //           <div>{user.displayName}</div>
  //           <div>{user.username}</div>
  //         </div>
  //       )}
  //     </div>
  //     {selectedUser &&
  //       <UserDetail uid={selectedUser.uid} />
  //     }
  //   </Stack>
  // )
  return (
    <Box sx={{p: 2}}>
      <Button variant="contained" sx={{ mb: 2 }} component={Link} to="/add">Add Friends</Button>

      <TableContainer>
        <Table>
          <TableBody>
            {users.map((user, index) =>
              <TableRow key={user.uid} onClick={() => setSelectedUser(user)}>
                <TableCell width={40}>
                  <Avatar alt={user.displayName} src={user.photoURL} />
                </TableCell>
                <TableCell sx={{pl: 0}}>
                  <ListItemText
                    primary={user.displayName}
                    secondary={user.username}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Drawer sx={{width: drawerWidth}} anchor="right" open={!!selectedUser} onClose={() => setSelectedUser(undefined)}>
        <Box sx={{width: drawerWidth, p: 2}}>
          {selectedUser &&
            <UserDetail uid={selectedUser.uid} />
          }
        </Box>
      </Drawer>
    </Box>
  );
}

export default Friends;