import { Box, Button, Stack, TextField, Typography, Link as MuiLink } from "@mui/material";
import { getAuth, sendSignInLinkToEmail } from "firebase/auth";
import { useState } from "react";
import { Link } from "react-router-dom";

const protocol = window.location.protocol;
const domain = window.location.host;
const url = protocol + '//' + domain + '/finishSignUp';

const actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be in the authorized domains list in the Firebase Console.
  url,
  // This must be true.
  handleCodeInApp: true,
  // iOS: {
  //   bundleId: 'com.example.ios'
  // },
  // android: {
  //   packageName: 'com.example.android',
  //   installApp: true,
  //   minimumVersion: '12'
  // },
  dynamicLinkDomain: 'addup.page.link'
};

function LoginEmail({ isEditing }: { isEditing: boolean }) {
  const auth = getAuth();
  const [email, setEmail] = useState('');
  const [didSend, setDidSend] = useState(false);

  const onSend = () => {
    sendSignInLinkToEmail(auth, email, actionCodeSettings)
      .then(() => {
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        window.localStorage.setItem('emailForSignIn', email);
        window.localStorage.setItem('isEditing', isEditing ? 'true' : 'false');
        setDidSend(true);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(errorCode, errorMessage);
      });
  }

  const onRetry = () => {
    setDidSend(false);
  }

  const title = isEditing ? 'Change your email' : 'Login with your email';

  return (
    <Box sx={{p: 2, width: '100%', maxWidth: 400}}>
      {didSend ? (
        <Stack>
          <Typography variant="h5">Email Sent!</Typography>
          <Typography variant="body2">Check your email for a link</Typography>
          <MuiLink variant="body2" onClick={onRetry}>Try with another email</MuiLink>
        </Stack>
      ) : (
        <Stack rowGap={2}>
          <Typography variant="h5">{title}</Typography>
          <Typography variant="body2">We'll send you an email with a verification link.</Typography>

          <TextField label="Email" value={email} onChange={e => setEmail(e.target.value)} />
          <Button variant="contained" onClick={onSend}>Send Link</Button>
          {!isEditing &&
            <Link to="/login">Use your phone number instead</Link>
          }
        </Stack>
      )}
    </Box>
  );
}

export default LoginEmail;
