import { Avatar, Box, Button, Grid, Typography, Link as MuiLink } from "@mui/material";
import { getDatabase, onValue, ref, remove, update } from "firebase/database";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import User from "../../models/User";
import { parseUser, providerColor, providerIcon, providerLink, providerTextColor, providerTitle } from "../../utils";

function UserDetail({ uid }: { uid: string }) {
  const db = getDatabase();
  const [user, setUser] = useState<User>();
  const auth = useAuth();
  const [currentUser, setCurrentUser] = useState<User>();

  useEffect(() => {
    const uid = auth.currentUser?.uid;
    if (uid) {
      console.log('current user signed in');
      const currentUserRef = ref(db, 'users/' + uid);
      const unsubscribe = onValue(currentUserRef, (snapshot) => {
        const user = parseUser(uid, snapshot.val());
        setCurrentUser(user);
      })
      return () => {
        unsubscribe();
      }
    }
  }, [db, auth.currentUser?.uid]);

  useEffect(() => {
    if (uid) {
      console.log('get user')
      const userRef = ref(db, 'users/' + uid);
      const unsubscribe = onValue(userRef, (snapshot) => {
        const userData = snapshot.val() as User;
        const user = parseUser(uid, userData);
        setUser(user);
        console.log('found user', user)
      }, (error) => {
        console.error(error);
      });
      return () => {
        unsubscribe();
      }
    }
  }, [db, uid]);

  const follow = () => {
    if (currentUser && user) {
      // add following to the current user
      const currentUserRef = ref(db, 'users/' + currentUser.uid);
      const currentUserUpdates: any = {};
      currentUserUpdates['/following/' + user.uid] = true;
      update(currentUserRef, currentUserUpdates);

      // add the follower to the user
      const userRef = ref(db, 'users/' + user.uid);
      const userUpdates: any = {};
      userUpdates['/followers/' + currentUser.uid] = true;
      update(userRef, userUpdates);
    }
  }

  const unfollow = () => {
    if (currentUser && user) {
      // remove the following reference on the current user
      const followingRef = ref(db, 'users/' + currentUser.uid + '/following/' + user.uid);
      remove(followingRef);

      // remove the follower reference on the user
      const followerRef = ref(db, 'users/' + user.uid + '/followers/' + currentUser.uid);
      remove(followerRef);
    }
  }

  const isFollowing = currentUser && user && currentUser.following.includes(user.uid);

  console.log('user', user)
  console.log('is following: ', isFollowing);
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}>
      {user &&
        <>
          <Avatar alt={user.displayName} src={user.photoURL} sx={{ width: 128, height: 128 }} />
          <Typography variant="h5" align="center">{user.displayName}</Typography>
          <Typography variant="subtitle1">{user.username} • {user.karma}</Typography>
          {auth.currentUser &&
            <Box sx={{m: 2}}>
              {isFollowing ? (
                <Button variant="contained" onClick={unfollow}>Added</Button>
              ) : (
                <Button variant="contained" onClick={follow}>Add</Button>
              )}
            </Box>
          }
          {!auth.currentUser &&
            <Button variant="contained" component={Link} to="/login">Login to Add</Button>
          }
          <Typography variant="body2" align="center" style={{ whiteSpace: 'pre-line' }}>{user.bio}</Typography>
          <Grid container sx={{mt: 2}} spacing={2}>
            {user.identities.map((identity) =>
              <Grid item key={identity.provider} xs={6}>
                <Button 
                  startIcon={providerIcon(identity.provider)}
                  variant="contained" 
                  component={MuiLink} 
                  href={providerLink(identity)} 
                  target="_blank" 
                  rel="noreferrer"
                  sx={{
                    backgroundColor: providerColor(identity.provider),
                    color: providerTextColor(identity.provider),
                  }}
                  fullWidth
                >{providerTitle(identity.provider)}</Button>
              </Grid>
            )}
          </Grid>
        </>
      }
    </Box>
  )
}

export default UserDetail;