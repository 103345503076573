import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import UserDetail from "../UserDetail/UserDetail";

function PublicUserDetail() {
  const { uid } = useParams();
  
  if (!uid) {
    return (
        <div>User not found</div>
    )
  }

  console.log('uid', uid)

  return (
    <Box sx={{p: 2}}>
      <UserDetail uid={uid} />
    </Box>
  )
}

export default PublicUserDetail;