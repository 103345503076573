import {
  Routes,
  Route,
} from "react-router-dom";
import LoginEmail from '../LoginEmail/LoginEmail';
import FinishSignUp from '../FinishSignUp/FinishSignUp';
import { initializeApp } from 'firebase/app';
import Home from "../Home/Home";
import RequireAuth from "../RequireAuth/RequireAuth";
import AuthProvider from "../../providers/AuthProvider/AuthProvider";
import LoginPhone from "../LoginPhone/LoginPhone";
import PublicUserDetail from "../PublicUserDetail/PublicUserDetail";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LoggedOut from "../LoggedOut/LoggedOut";

const firebaseConfig = {
  apiKey: "AIzaSyACRFKwnrABY0gFBoliitUeFfPuRIZAD0Y",
  authDomain: "addup-app.firebaseapp.com",
  databaseURL: "https://addup-app.firebaseio.com",
  projectId: "addup-app",
  storageBucket: "addup-app.appspot.com",
  messagingSenderId: "972498147028",
  appId: "1:972498147028:web:c73632973b616eb8b20d41",
  measurementId: "G-SN1WNP88B3"
};
initializeApp(firebaseConfig);

const theme = createTheme({
  palette: {
    primary: {
      main: '#30A6FF',
      contrastText: '#ffffff'
    }
  },
});


function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <Routes>
          <Route path="/login" element={
            <LoggedOut>
              <LoginPhone isEditing={false} />
            </LoggedOut>
          }/>
          <Route path="/login/email" element={
            <LoggedOut>
              <LoginEmail isEditing={false} />
            </LoggedOut>
          } />
          <Route path="/finishSignUp" element={<FinishSignUp />} />
          <Route path="/add/:uid" element={<PublicUserDetail />} />
          <Route path="/*" element={
            <RequireAuth>
              <Home />
            </RequireAuth>
          }/>
        </Routes>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
