import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import { ConfirmationResult, getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { getDatabase, ref, set } from "firebase/database";
import './LoginPhone.css';

declare global {
  interface Window {
    recaptchaVerifier: any;
  }
}


function LoginPhone({ isEditing }: { isEditing: boolean }) {
  const auth = getAuth();
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [confirmationResult, setConfirmationResult] = useState<ConfirmationResult | null>(null);
  const [code, setCode] = useState('');
  const [isSignedIn, setIsSignedIn] = useState(false);
  const db = getDatabase();

  const onSignInSubmit = () => {

    window.recaptchaVerifier = new RecaptchaVerifier('sign-in-button', {
      'size': 'invisible',
      'callback': (response: any) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
      }
    }, auth);

    const appVerifier = window.recaptchaVerifier;

    signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        // window.confirmationResult = confirmationResult;
        setConfirmationResult(confirmationResult);
      }).catch((error) => {
        // Error; SMS not sent
        console.error(error);
      });
  }

  const onConfirm = () => {
    confirmationResult!.confirm(code).then((result) => {
      // User signed in successfully.
      // const user = result.user;
      if (isEditing) {
        updateUser();
      } else {
        setIsSignedIn(true);
      }
    }).catch((error) => {
      // User couldn't sign in (bad verification code?)
      console.error(error);
    });
  }

  const updateUser = () => {
    const uid = auth.currentUser?.uid;
    if (uid) {
      const phoneNumberRef = ref(db, 'users/' + uid + '/phoneNumber');
      set(phoneNumberRef, phoneNumber);
      setIsSignedIn(true);
    }
  }

  const onRetry = () => {
    setConfirmationResult(null);
  }

  useEffect(() => {
    if (isSignedIn) {
      navigate('/');
    }
  }, [isSignedIn, navigate]);

  const title = isEditing ? 'Change your phone number' : 'Login with your phone';
  const buttonText = isEditing ? 'Save' : 'Sign In';

  return (
    <Box sx={{ p: 2, width: '100%', maxWidth: 400 }}>
      {confirmationResult ? (
        <Stack rowGap={2}>
          <Typography variant="h5">{title}</Typography>
          <Typography variant="body2">Sent</Typography>
          <TextField label="Code" value={code} onChange={e => setCode(e.target.value)} />
          <Button variant="contained" onClick={onConfirm}>Confirm</Button>
          <Button onClick={onRetry}>Try with another phone number</Button>
        </Stack>
      ) : (
        <Stack rowGap={2}>
          <Typography variant="h5">{title}</Typography>
          <Typography variant="body2">We'll send you an SMS text message with a verification code.</Typography>

          <PhoneInput
            placeholder="Enter phone number"
            value={phoneNumber}
            onChange={e => setPhoneNumber(e as string)}
            defaultCountry="US"
          />

          <Button variant="contained" id="sign-in-button" onClick={onSignInSubmit}>{buttonText}</Button>
          {!isEditing &&
            <Link to="/login/email">Use your email instead</Link>
          }
        </Stack>
      )}

    </Box>
  );
}

export default LoginPhone;
