import { User } from "firebase/auth";
import { createContext } from "react";

interface AuthContextType {
    currentUser: User | null;
    isLoaded: boolean;
}

const AuthContext = createContext<AuthContextType>(null!);

export default AuthContext;
