import { PhotoCamera } from "@mui/icons-material";
import { Avatar, Box, Button, Divider, IconButton, Link, Stack, styled, TextField, Typography } from "@mui/material";
import { getDatabase, onValue, ref as dbRef, update } from "firebase/database";
import { ChangeEvent, useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import User from "../../models/User";
import { parseUser } from "../../utils";
import { getApp } from "firebase/app";
import { getDownloadURL, getStorage, ref as stRef, uploadBytes } from "firebase/storage";

const Input = styled('input')({
  display: 'none',
});

function EditProfile() {
  const db = getDatabase();
  const auth = useAuth();
  const [user, setUser] = useState<User>();
  const [photoURL, setPhotoURL] = useState<string>();
  const [file, setFile] = useState<File>();
  const [username, setUsername] = useState<string>();
  const [displayName, setDisplayName] = useState<string>();
  const [bio, setBio] = useState<string>();
  const [isDeleting, setDeleting] = useState<boolean>(false);

  const firebaseApp = getApp();
  const storage = getStorage(firebaseApp);

  useEffect(() => {
    const uid = auth.currentUser?.uid;
    if (uid) {
      const currentUserRef = dbRef(db, 'users/' + uid);
      const unsubscribe = onValue(currentUserRef, (snapshot) => {
        const user = parseUser(uid, snapshot.val());
        setUser(user);
        setPhotoURL(user.photoURL);
        setUsername(user.username);
        setDisplayName(user.displayName);
        setBio(user.bio);
      })
      return () => {
        unsubscribe();
      }
    }
  }, [db, auth.currentUser?.uid]);

  const onImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const file = files[0];
      setPhotoURL(URL.createObjectURL(file));
      setFile(file);
    }
  };

  const onSave = () => {
    if (user) {
      if (file) {
        const storageRef = stRef(storage, 'users/' + user.uid + 'photo.jpg');
        const metadata = {
          contentType: 'image/jpeg',
        };
        uploadBytes(storageRef, file, metadata).then((snapshot) => {
          console.log('Uploaded a blob or file!');
          getDownloadURL(snapshot.ref).then((downloadURL) => {
            console.log('File available at', downloadURL);
            saveUser(downloadURL);
          });
        });
      } else {
        saveUser(null);
      }
    }
  }

  const saveUser = (photoURL: string | null) => {
    if (user) {
      const currentUserRef = dbRef(db, 'users/' + user.uid);
      const currentUserUpdates: any = {
        username: username,
        displayName: displayName,
        bio: bio,
        photoURL: photoURL ? photoURL : user.photoURL,
        email: user.email,
        phoneNumber: user.phoneNumber
      };
      update(currentUserRef, currentUserUpdates);
    }
  }

  const deleteAccount = () => {
    setDeleting(false);
  }
  
  return (
    <Box sx={{p: 2}}>
      {user &&
        <Stack rowGap={4}>
          <Typography variant="h4">Edit Profile</Typography>

          <Box sx={{position: 'relative', width: 128, display: 'inline-block'}}>
            <Avatar alt={user.displayName} src={photoURL} sx={{ width: 128, height: 128 }} />
            <label htmlFor="icon-button-file" style={{position: 'absolute', bottom: '0px', right: '0px'}}>
              <Input accept="image/*" id="icon-button-file" type="file" onChange={(e) => onImageChange(e)} />
              <IconButton 
                sx={{ 
                  backgroundColor: '#1976d2',
                  '&:hover': {
                    backgroundColor: '#1976d2',
                  },
                  color: '#ffffff' ,
                }} 
                aria-label="upload picture" 
                component="span"
              >
                <PhotoCamera />
              </IconButton>
            </label>
          </Box>

          <TextField label="Username" value={username} onChange={e => setUsername(e.target.value)} />

          <TextField label="Your Full Name" value={displayName} onChange={e => setDisplayName(e.target.value)} />

          <TextField multiline rows={4} label="Tell us about yourself" value={bio} onChange={e => setBio(e.target.value)} />

          <Button variant="contained" onClick={onSave}>Save</Button>

          <Divider/>

          {isDeleting ? (
            <Button variant="contained" color="error" onClick={deleteAccount}>Delete Account</Button>
          ) : (
            <Link onClick={() => setDeleting(true)} color="error" variant="body2">Want to delete your account?</Link>
          )}

        </Stack>
      }
    </Box>
  )
}

export default EditProfile;