import { Route, Routes } from "react-router-dom";
import AddFriends from "../AddFriends/AddFriends";
import EditProfile from "../EditProfile/EditProfile";
import Friends from "../Friends/Friends";
import HomeLayout from "../HomeLayout/HomeLayout";
import Leaderboard from "../Leaderboard/Leaderboard";
import LoginEmail from "../LoginEmail/LoginEmail";
import LoginPhone from "../LoginPhone/LoginPhone";
import MyProfile from "../MyProfile/MyProfile";

function Home() {
  return (
    <Routes>
      <Route path="/*" element={<HomeLayout />}>
        <Route path="/*" element={<Leaderboard />} />
        <Route path="leaderboard/*" element={<Leaderboard />} />
        <Route path="friends" element={<Friends />} />
        <Route path="add" element={<AddFriends />} />
        <Route path="settings">
          <Route path="accounts" element={<MyProfile />} />
          <Route path="profile" element={<EditProfile />} />
          <Route path="phone" element={<LoginPhone isEditing={true} />} />
          <Route path="email" element={<LoginEmail isEditing={true} />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default Home;
