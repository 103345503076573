import { Box, Stack, Typography } from "@mui/material";
import logo from '../App/logo.svg';

function LoggedOut({ children }: { children: JSX.Element }) {
  return (
    <Stack direction="row" sx={{position: 'fixed', width: '100%', height: '100%'}}>
      <Box sx={{
        bgcolor: 'primary.main', 
        height: '100%', 
        width: '50%', 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        flexDirection: 'column'
      }}>
        <img src={logo} style={{ width: 48, display: 'block' }} alt="AddUp" />
        <Typography variant="h4" sx={{p: 2, color: '#ffffff'}}>
          AddUp
        </Typography>
      </Box>
      <Box sx={{
        display: 'flex', 
        alignItems: 'center', 
        width: '50%',
        justifyContent: 'center',
      }}>
        {children}
      </Box>
    </Stack>
  )
}

export default LoggedOut;